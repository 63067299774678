import * as React from 'react'
import styled from 'styled-components'

// ================================================================================================

export interface Props {
  active: boolean
  onToggle?: () => void
}

// ================================================================================================

const Checkbox: React.FC<Props> = ({ active, onToggle }) => {
  return (
    <C
      active={active}
      onClick={() => {
        if (onToggle) {
          onToggle()
        }
      }}
    >
      <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M64 64L14 14m50 50l50-50M64 64l50 50M64 64l-50 50" strokeLinecap="round" />
      </svg>
      <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M64 64L14 14m50 50l50-50M64 64l50 50M64 64l-50 50" strokeLinecap="round" />
      </svg>

      <div>
        {active ? (
          <svg fill="none" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 68.896l46.299 41.343a1 1 0 001.493-.184L120 17" strokeWidth="16" />
          </svg>
        ) : (
          <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M64 64L14 14m50 50l50-50M64 64l50 50M64 64l-50 50" strokeLinecap="round" />
          </svg>
        )}
      </div>
    </C>
  )
}

// ================================================================================================

const C = styled.button<any>`
  position: relative;
  width: 1.75rem;
  height: 0.875rem;
  background: #d8dde2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 2px;

  > svg {
    width: 0.375rem;
    height: 0.375rem;

    path {
      stroke: rgba(0, 0, 0, 0.15);
      strokewidth: 20px;
    }
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    transform: ${({ active }) => (active ? 'translateX(100%)' : 'none')};
    transition: 0.25s;
    background: ${({ active }) => (active ? '#122A43' : '#7E8C9B')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;

    svg {
      width: 0.375rem;
      height: 0.375rem;

      path {
        stroke: #fff;
        strokewidth: 20px;
      }
    }
  }
`

// ================================================================================================

export default Checkbox
