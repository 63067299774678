import React, { useCallback, useState, SyntheticEvent } from 'react'
import styled from 'styled-components'
import * as Parse from 'parse'
// import { RouteComponentProps } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'

// Components
import RatingInput from '../components/RatingInput'
import Layout from '../components/Layout'
import TextArea from '../components/TextArea'
import TextInput from '../components/TextInput'

// Store
import { useDialogStore, useUserStore } from '../store'

// Types
import { CriterionData, Criterion } from '../types'

// Utils
import { data } from '../utils/radar'
import { getUrlParams } from '../utils/url'
import { useLocation } from 'react-router-dom'

// ================================================================================================

const RequestPage: React.FC = () => {
  const location = useLocation()
  // Url params
  const { q: urlSearchQuery } = getUrlParams(location.search)

  // Global store
  const { showErrorDialog, showSuccessDialog } = useDialogStore()
  const { email, userIsAdmin } = useUserStore()

  // Local state
  const [loading, setLoading] = useState(false)
  const [newTrendDecription, setNewTrendDecription] = useState('')
  const [newTrendQuery, setNewTrendQuery] = useState(
    urlSearchQuery && typeof urlSearchQuery === 'string' ? urlSearchQuery : ''
  )
  const [newTrendTitle, setNewTrendTitle] = useState('')

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (newTrendTitle.length) {
      setLoading(true)

      try {
        let Request

        if (userIsAdmin) {
          Request = Parse.Object.extend('Topic')
        } else {
          Request = Parse.Object.extend('TopicRequest')
        }

        const request = new Request()

        request.set('title', newTrendTitle)
        if (newTrendDecription) request.set('description', newTrendDecription)
        if (newTrendQuery) request.set('query', newTrendQuery)
        if (!userIsAdmin && email) request.set('user', email)

        await request.save()

        showSuccessDialog('Submission successful!')

        setNewTrendDecription('')
        setNewTrendQuery('')
        setNewTrendTitle('')
      } catch (error) {
        showErrorDialog(`Error: ${error.code} ${error.message}`)
      }

      setLoading(false)
    }
  }

  return (
    <Layout>
      <Wrapper>
        <PageTitle>Topic Request</PageTitle>

        <Widget>
          <WidgetTitle>New Topic</WidgetTitle>
          <WidgetSubTitle>Submit a request for a new trend topic.</WidgetSubTitle>

          <Form>
            <div>
              <TextInput
                label="Name"
                onTextChange={input => setNewTrendTitle(input)}
                onClear={() => setNewTrendTitle('')}
                placeholder="Short but descriptive name"
                value={newTrendTitle}
                width="33%"
              />
              <TextInput
                label="Search Query"
                onTextChange={input => setNewTrendQuery(input)}
                onClear={() => setNewTrendQuery('')}
                placeholder='e.g. (Term OR "Other term") AND "A third term"  (optional)'
                value={newTrendQuery}
                width="64%"
              />
            </div>
            <TextArea
              label="Description"
              onTextChange={input => setNewTrendDecription(input)}
              placeholder="Description (optional)"
              value={newTrendDecription}
            />

            <Submit loading={loading} onClick={handleSubmit}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
                  <path d="M17 21v-8H7v8M7 3v5h8" />
                </svg>
              </div>
              <span>Submit</span>
            </Submit>
          </Form>
        </Widget>
      </Wrapper>
    </Layout>
  )
}

export default RequestPage

// ================================================================================================

const Wrapper = styled.section`
  background: ${({ theme }) => theme.base};
  padding: 3rem 4rem;
`

const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
  margin: 0 0 4rem;
`

const Widget = styled.div`
  background: ${({ theme }) => theme.surface};
  padding: 2rem;
  width: 100%;
  max-width: 960px;
`

const WidgetTitle = styled.h2`
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  color: ${({ theme }) => theme.text};
`

const WidgetSubTitle = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.text};
  margin: 0.5rem 0 3rem;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    &:first-child {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      > div {
        margin-bottom: 1.25rem;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
`

interface SubmitProps {
  loading?: boolean
}
const Submit = styled.button<SubmitProps>`
  height: 2.75rem;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2cb1bc;
  border-radius: 1.5rem;
  margin: 3rem 0 0;
  pointer-events: ${({ loading }) => (loading ? 'none' : 'all')};

  svg {
    width: 1rem;
    height: 1rem;
    margin: 3px 0 0;
  }

  span {
    font-size: 0.8125rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    margin: 0 0 0 0.5rem;
    line-height: 1;
  }

  &:hover {
    background: #299ba5;
  }
`
