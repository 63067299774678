import React, { FC } from 'react'
import styled from 'styled-components'

interface Props {
  color?: string
  size?: number
  stroke?: number
}

const Loader: FC<Props> = ({ color, size, stroke = 4 }) => {
  return (
    <Wrapper color={color} size={size}>
      <svg fill="none" viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="20" strokeWidth={stroke}></circle>
      </svg>
    </Wrapper>
  )
}

export default Loader

const Wrapper = styled.div<any>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;

  svg {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: ${({ size }) => (size ? `-${size / 2}px 0 0 -${size / 2}px` : '-25px 0 0 -25px')};
    width: ${({ size }) => (size ? `${size}px` : '36px')};
    height: ${({ size }) => (size ? `${size}px` : '36px')};
  }

  svg circle {
    stroke: ${({ color }) => (color ? color : 'rgba(0, 0, 0, 0.1)')};
    stroke-linecap: round;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`
