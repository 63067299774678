import React, { useState } from 'react'
import styled from 'styled-components'
import TextInput from './TextInput'
import { useUserStore } from '../store'
import { useNavigate } from 'react-router-dom'

// ================================================================================================

export interface SearchProps {
  onSubmit: (search: string) => void
  showSaveBtn?: boolean
}

const Search: React.FC<SearchProps> = ({ onSubmit, showSaveBtn }) => {
  const navigate = useNavigate()
  const { userIsAdmin } = useUserStore()

  const [search, setSearch] = useState('')

  const handleSave = () => {
    if (userIsAdmin) {
      navigate(`/manage/new-topic?q=${search}`)
    } else {
      navigate(`/manage/request?q=${search}`)
    }
  }

  return (
    <Wrapper>
      <TextInput
        icon="search"
        onClear={() => setSearch('')}
        onSubmit={() => onSubmit(search)}
        onTextChange={(input: string) => setSearch(input)}
        placeholder="Search"
        value={search}
      />
      <SearchBtn onClick={() => onSubmit(search)}>Search</SearchBtn>
      {showSaveBtn && userIsAdmin ? (
        <SaveBtn enabled={search.length > 1} onClick={handleSave}>
          Save
        </SaveBtn>
      ) : (
        <SaveBtn enabled={search.length > 1} onClick={handleSave}>
          Request
        </SaveBtn>
      )}
    </Wrapper>
  )
}

export default Search

// ================================================================================================

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.75rem;
`

const SearchBtn = styled.button`
  height: 3.125rem;
  flex: 0 0 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #102a43;
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    background-color: #0b1b2b;
  }
`

interface SaveBtnProps {
  enabled?: boolean
}
const SaveBtn = styled.button<SaveBtnProps>`
  height: 3.125rem;
  flex: 0 0 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ enabled }) => (enabled ? '#14919b' : '#e5eaef')};
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 1rem;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};

  &:hover {
    background-color: ${({ enabled }) => (enabled ? '#0E7C86' : '#dbe1e6')};
  }
`
