import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useScroll } from 'react-use'

// Components
import Nav from './Nav'

// Store
import { useAppStore } from '../store'

// ================================================================================================

const Layout: React.FC<any> = ({ children }) => {
  const ref = useRef()
  const { y } = useScroll(ref)

  const { setScrollPosY } = useAppStore()

  useEffect(() => {
    setScrollPosY(y)
  }, [y])

  return (
    <>
      <Wrapper>
        <Nav />

        <Content
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          ref={ref}
          transition={{ delay: 0.125, duration: 0.25, type: 'tween' }}
        >
          {children}
        </Content>
      </Wrapper>
    </>
  )
}

// ================================================================================================

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.base};
`

const Content = styled(motion.div)`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-anchor: none;
`

// ================================================================================================

export default Layout
