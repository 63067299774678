import React, { FC } from 'react'
import styled from 'styled-components'
// import Framer from 'framer-motion'
import { motion } from 'framer-motion'
import { formatDate, getExcerpt } from '../utils/string'

// ================================================================================================

interface Props {
  data: any
}

const Article: FC<Props> = ({ data }) => {
  const { description, publishedAt, source, title, url, urlToImage } = data

  return (
    <Wrapper
      animate={{ opacity: 1 }}
      href={url ? url : '#'}
      initial={{ opacity: 0 }}
      target="_blank"
    >
      {urlToImage ? (
        <Image src={urlToImage} />
      ) : (
        <Placeholder>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
            <path d="M49 32a2 2 0 00-2 2v2a2 2 0 002 2h48a2 2 0 002-2v-2a2 2 0 00-2-2H49zM47 58a2 2 0 012-2h48a2 2 0 012 2v2a2 2 0 01-2 2H49a2 2 0 01-2-2v-2zM49 80a2 2 0 00-2 2v2a2 2 0 002 2h48a2 2 0 002-2v-2a2 2 0 00-2-2H49z" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25 8a4 4 0 014-4h85a4 4 0 014 4v112a4 4 0 01-4 4H25v-.166c-.65.109-1.319.166-2 .166-6.627 0-12-5.373-12-12V35a4 4 0 014-4h10V8zm6 110V10h81v108H31zm-6-81v80.659A6 6 0 0117 112V37h8z"
            />
          </svg>
        </Placeholder>
      )}
      <Content>
        {(source || publishedAt) && (
          <Header>
            {!!publishedAt && <span>{formatDate(publishedAt)}</span>}
            {source && source.name && publishedAt && <span>{'•'}</span>}
            {!!source && source.name && <span>{getExcerpt(source.name, 50)}</span>}
          </Header>
        )}

        <Title>{getExcerpt(title, 100)}</Title>
        {!!description && <Excerpt>{getExcerpt(description, 140)}</Excerpt>}
      </Content>
    </Wrapper>
  )
}

export default Article

// ================================================================================================

const Wrapper = styled(motion.a)`
  width: 100%;
  background: ${({ theme }) => theme.surface};
  border-radius: 3px;
  display: flex;
  padding: 1.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.025);

  &:hover {
    box-shadow: 0 6px 12px -4px rgba(0, 0, 0, 0.15);
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  display: flex;
  margin: 0 0 0.375rem;

  span {
    color: #758696;
    font-size: 0.8125rem;
    margin-right: 0.375rem;
  }
`

const Title = styled.h3`
  font-size: 1rem;
  line-height: 1.25;
  color: ${({ theme }) => theme.text};
`

const Excerpt = styled.p`
  line-height: 1.25;
  color: #758696;
  font-size: 0.8125rem;
  margin-top: 0.5rem;
`

interface ImageProps {
  src?: string
}
const Image = styled.div<ImageProps>`
  flex: 0 0 54px;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1.5rem;
`

const Placeholder = styled.div`
  flex: 0 0 54px;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: ${({ theme }) => theme.base};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;

  & > svg {
    fill: rgba(0, 0, 0, 0.67);
    width: 36%;
    height: 36%;
  }
`
