import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

// Components
import Loader from '../Loader'
import NoResults from '../../components/NoResults'
import Overlay from '../../components/Overlay'

// Partials
import ItemContent from './ItemContent'

// Store
import { useTopicStore, useUserStore } from '../../store'

// Types
import { Topic } from '../../types'

// ================================================================================================

const Board: FC = () => {
  const navigate = useNavigate()
  const { loading, topics } = useTopicStore()

  const [momentumFilter, setMomentumFilter] = useState<'week' | 'month' | 'quarter'>('month')
  const [openTopic, setOpenTopic] = useState<Topic | null>(null)

  // ============================================

  const handleDetailsClick = (topic: Topic) => {
    setOpenTopic(topic)
  }

  // ============================================

  return (
    <Wrapper>
      {loading ? (
        <LoadingSpinner>
          <Loader size={24} stroke={3} />
        </LoadingSpinner>
      ) : !loading && !topics.length ? (
        <Center>
          <NoResults headline="Oops!" message="Looks like there is no data to show." />
        </Center>
      ) : (
        <>
          <Filter>
            <FilterItem>
              <Label>Momentum</Label>
              <Select>
                <SelectButton
                  active={momentumFilter === 'week'}
                  onClick={() => setMomentumFilter('week')}
                >
                  1 Week
                </SelectButton>
                <SelectButton
                  active={momentumFilter === 'month'}
                  onClick={() => setMomentumFilter('month')}
                >
                  1 Month
                </SelectButton>
                <SelectButton
                  active={momentumFilter === 'quarter'}
                  onClick={() => setMomentumFilter('quarter')}
                >
                  3 Months
                </SelectButton>
              </Select>
            </FilterItem>
          </Filter>
          <Tiles>
            {topics &&
              topics.length &&
              topics.map((t: Topic, i: number) => {
                const { image, imageUrl, title } = t

                return (
                  <Tile key={`board-tile-${i}`}>
                    <Thumb
                      onClick={() => handleDetailsClick(t)}
                      url={image ? image : imageUrl ? imageUrl : ''}
                    >
                      <TopicTitle>{title}</TopicTitle>
                    </Thumb>

                    <ItemContent
                      momentumFilter={momentumFilter}
                      onDetailsClick={handleDetailsClick}
                      topic={t}
                      totalNewsCount={300000}
                    />
                  </Tile>
                )
              })}
          </Tiles>
        </>
      )}

      <Overlay onClose={() => setOpenTopic(null)} open={!!openTopic} topic={openTopic} />
    </Wrapper>
  )
}

export default Board

// ================================================================================================

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.base};
`

const LoadingSpinner = styled.div`
  height: 100vh;
  width: 4rem;
`

const Filter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const FilterItem = styled.div`
  background: ${({ theme }) => theme.surface};
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0.75rem 1rem;
  margin: 0 1rem 0 0;
`

const Label = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.text};
  margin-right: 1rem;
  line-height: 1;
  margin-top: 2px;
`

const Select = styled.div`
  display: flex;
  align-items: center;

  > button {
    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }
`
interface SelectButtonProps {
  active?: boolean
}
const SelectButton = styled.button<SelectButtonProps>`
  width: 4.5rem;
  height: 2rem;
  background: ${({ active, theme }) => (active ? '#122A43' : theme.base)};
  font-size: 0.6875rem;
  color: ${({ active, theme }) => (active ? '#fff' : theme.text)};
  font-weight: bold;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`

const Tiles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* Fallback end */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-auto-rows: minmax(150px, auto);
  grid-gap: 1rem;
`

const Tile = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.surface};
`

interface ThumbProps {
  url: string
}
const Thumb = styled.button<ThumbProps>`
  position: relative;
  padding: 0 0 60%;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.surface};
    background-image: ${({ url }) => `url(${url})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -2;
    transition: 0.25s;
    transform-origin: center;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 100%);
    opacity: 1;
    z-index: -1;
  }

  &:hover {
    &:before {
      transform: scale(1.1);
    }

    > button {
      opacity: 0.8;
    }
  }
`

const TopicTitle = styled.h2`
  position: absolute;
  top: 2rem;
  left: 2rem;
  right: 2rem;
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: left;
  @media (min-width: 1400px) {
    font-size: 1.75rem;
  }
`

const Center = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
