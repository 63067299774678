import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

// ================================================================================================

export interface ExcerptProps {
  length?: number
  externalLink?: string
  showReadMore?: boolean
  text: string
}

// ================================================================================================

const Excerpt: React.FC<ExcerptProps> = ({ length = 240, externalLink, showReadMore, text }) => {
  const [showFullText, setShowFullText] = useState(false)

  // ============================================

  const stripHTMLTags = (str: string) => {
    if (!str) return ''
    else str = str.toString()
    str = str.replace(/<li>|<ul>|<br\/>|<br \/>/g, ' ')
    str = str.replace(/<\/li>|<\/ul>/g, ', ')
    str = str.replace(/<[^>]*>/g, '')
    str = str.replace(/^\s*/g, '')
    return str
  }

  const getExcerpt = () => {
    const plainStr = stripHTMLTags(text)
    return plainStr.length > length ? `${plainStr.substr(0, length)} ...` : plainStr
  }

  // ============================================

  if (showReadMore) {
    return (
      <Wrapper className="excerpt">
        <p>
          {showFullText ? text : getExcerpt()}
          {!showFullText && text?.length > length && (
            <ReadMore onClick={() => setShowFullText(true)}>
              <b>Read More</b>
              {/* <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M103.663 30.943l21.287 32.018-21.253 33.037-10.394-5.996L107.612 69H-.5V57h107.974L93.337 37.057l10.326-6.114z"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path transform="matrix(-1 0 0 1 126 0)" d="M0 0h126v126H0z" />
                  </clipPath>
                </defs>
              </svg> */}
            </ReadMore>
          )}
        </p>
        {!!externalLink && showFullText && (
          <ExternalLink href={externalLink} rel="noopener noreferrer" target="_blank">
            <span>Show Article</span>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.707 2.293a1 1 0 010 1.414l-11 11a1 1 0 01-1.414-1.414l11-11a1 1 0 011.414 0z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 7a1 1 0 00-1 1v11a1 1 0 001 1h11a1 1 0 001-1v-6a1 1 0 112 0v6a3 3 0 01-3 3H5a3 3 0 01-3-3V8a3 3 0 013-3h6a1 1 0 110 2H5zM14 3a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 11-2 0V4h-5a1 1 0 01-1-1z"
              />
            </svg>
          </ExternalLink>
        )}
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <p>{getExcerpt()}</p>
      </Wrapper>
    )
  }
}

// ================================================================================================
interface WrapperProps {
  light?: boolean
}
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: ${({ light, theme }) => (light ? theme.textLight : theme.text)};
    margin-bottom: 1.5rem;
  }
`

const ReadMore = styled.button`
  display: inline-block;
  margin-left: 0.625rem;
  line-height: 1.5;

  b {
    font-size: 1rem;
    color: #243b53;
    font-weight: 600;
    text-transform: none;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    margin: 0 0.375rem 0 0;
  }

  svg {
    width: 17px;
    height: 17px;
    fill: #243b53;
    vertical-align: middle;
    margin-bottom: 3px;
  }
`

const ExternalLink = styled.a`
  margin-top: 1rem;

  span {
    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) => theme.text};
    margin: 0 0.375rem 0 0;
  }

  svg {
    width: 17px;
    height: 17px;
    fill: #243b53;
    vertical-align: middle;
    margin-bottom: 3px;
  }
`

// ================================================================================================

export default Excerpt
