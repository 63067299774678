import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

// Components
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import Overlay from '../components/Overlay'

// Store
import { useTopicStore, useUserStore } from '../store'

// Types
import { Topic } from '../types'

// ================================================================================================

interface MoodBoardProps {
  activeTopic?: Topic
  data?: any
  loading?: boolean
  onCloseDetails?: () => void
  onShowDetails?: (id: string) => void
}

// ================================================================================================

const MoodBoard: React.FC<MoodBoardProps> = ({ data, loading, onCloseDetails, onShowDetails }:any) => {
  const navigate = useNavigate()
  const { loading: topicsLoading, topics } = useTopicStore()
  const { userIsAdmin } = useUserStore()

  const [openTopic, setOpenTopic] = useState<Topic | null>(null)

  const handleAddTopic = () => {
    if (userIsAdmin) {
      navigate('/manage/new-topic')
    } else {
      navigate('/manage/request')
    }
  }

  return (
    <Layout>
      <Wrapper>
        {(loading || topicsLoading) && (
          <LoadingSpinner>
            <Loader color="#102a43" />
          </LoadingSpinner>
        )}

        {!loading && !topicsLoading && topics && topics.length && (
          <Grid>
            {topics.map((t: Topic, i) => {
              const { image, imageUrl, title } = t

              return (
                <Tile
                  image={image ? image : imageUrl ? imageUrl : ''}
                  key={`${t.id}-${i}`}
                  large={i === 0 || i === 7 || i === 10 || i === 17}
                  onClick={() => setOpenTopic(t)}
                >
                  <TileHeader>
                    <h2>{title}</h2>
                  </TileHeader>
                </Tile>
              )
            })}
            <Tile add key="add-topic-tile" onClick={handleAddTopic}>
              <AddButton>+</AddButton>
            </Tile>
          </Grid>
        )}
      </Wrapper>

      <Overlay onClose={() => setOpenTopic(null)} open={!!openTopic} topic={openTopic} />
    </Layout>
  )
}

export default MoodBoard

// ================================================================================================

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 1vw;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 20vw;
  grid-auto-flow: dense;
`
interface TileProps {
  add?: boolean
  image: string
  large?: boolean
}
const Tile = styled.button<TileProps|any>`
  position: relative;
  grid-column: ${({ large }) => (large ? 'auto / span 2' : 'auto / span 1')};
  grid-row: ${({ large }) => (large ? 'auto / span 2' : 'auto / span 1')};
  z-index: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0.25rem;

  &:before,
  &:after {
    content: '';
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.surface};
    background-image: ${({ add, image }) => (add ? '' : `url(${image})`)};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -2;
    transition: 0.25s;
    transform-origin: center;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ add }) =>
      add ? '#E0E6EB' : 'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 100%)'};
    opacity: 1;
    z-index: -1;
  }

  &:hover {
    &:before {
      transform: scale(1.1);
    }

    span {
      color: rgba(34, 58, 82, 0.95);
    }
  }
`

const TileHeader = styled.header`
  width: 100%;
  padding: 2.5vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  h2 {
    font-size: 2vw;
    color: #fff;
    text-align: left;
  }
`

const AddButton = styled.span`
  font-size: 8rem;
  font-weight: 300;
  color: rgba(34, 58, 82, 0.6);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingSpinner = styled.div`
  height: 10rem;
  width: 100%;
`
