import React, { useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

// Pages
import DashboardPage from './pages/dashboard'
import RatingPage from './pages/rating'
import MoodboardPage from './pages/moodboard'
import NewTopicPage from './pages/new'
import RadarPage from './pages/radar'
import RoadmapPage from './pages/roadmap'
import RequestPage from './pages/request'
import ScenarioPage from './pages/scenario'
import SignalsPage from './pages/signals'
import SignInPage from './pages/signin'
import SignUpPage from './pages/signup'

// Store
import { AppStore, DialogStore, TopicStore, UserStore } from './store'

// Style (global)
import AppStyles from './AppStyles'

// Theme
import { ThemeSelector, useTheme } from './theme'

// ================================================================================================
const App = () => {
  const [selectedTheme, setSelectedTheme] = useState<ThemeSelector>('light')
  const theme = useTheme(selectedTheme)

  return (
    <ThemeProvider theme={theme}>
      <AppStore>
        <UserStore>
          <DialogStore>
            <TopicStore>
              <AppStyles />
              <Routes>
                <Route path="/" element={<SignInPage />} />
                <Route path="/sign-up" element={<SignUpPage />} />
                <Route path="/monitor" element={<DashboardPage />} />
                <Route path="/monitor/dashboard" element={<DashboardPage />} />
                <Route path="/monitor/moodboard" element={<MoodboardPage />} />
                <Route path="/monitor/radar" element={<RadarPage />} />
                <Route path="/monitor/roadmap" element={<RoadmapPage />} />
                <Route path="/monitor/scenario" element={<ScenarioPage />} />
                <Route path="/evaluate" element={<RatingPage />} />
                <Route path="/evaluate/rating" element={<RatingPage />} />
                <Route path="/scout" element={<SignalsPage />} />
                <Route path="/scout/signals" element={<SignalsPage />} />
                <Route path="/manage" element={<RequestPage />} />
                <Route path="/manage/request" element={<RequestPage />} />
                <Route path="/manage/new-topic" element={<NewTopicPage />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </TopicStore>
          </DialogStore>
        </UserStore>
      </AppStore>
    </ThemeProvider>
  )
}

// ================================================================================================
export default App
