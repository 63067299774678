import React, { createContext, FC, ReactNode, useState } from 'react'
import Dialog from '../components/Dialog'

// ================================================================================================

export const DialogContext = createContext({
  showErrorDialog: (text: any) => {},
  showInfoDialog: (text: string) => {},
  showSuccessDialog: (text: string) => {}
})

interface DialogStoreProps {
  children: ReactNode
}

// ================================================================================================

const DialogStore: FC<DialogStoreProps> = ({ children }) => {
  const [dialog, setDialog] = useState<'error' | 'info' | 'success' | null>(null)
  const [text, setText] = useState<string>('')

  const handleShowDialog = (type: string, text: any) => {
    switch (type) {
      case 'error': {
        if (typeof text === 'string') {
          setText(text)
        } else if (text.message) {
          setText(text.message)
        } else {
          setText('An error occured. Please try again.')
        }

        setDialog('error')
        break
      }

      case 'success': {
        setText(text)
        setDialog('success')
        break
      }

      default: {
        setText(text)
        setDialog('info')
        break
      }
    }
  }

  return (
    <DialogContext.Provider
      value={{
        showErrorDialog: (text: string) => handleShowDialog('error', text),
        showInfoDialog: (text: string) => handleShowDialog('info', text),
        showSuccessDialog: (text: string) => handleShowDialog('success', text)
      }}
    >
      {children}
      <Dialog onClose={() => setDialog(null)} text={text} type={dialog} />
    </DialogContext.Provider>
  )
}

export default DialogStore
