import { Topic, Type } from '../types'

interface Props {
  activeTopics: Array<Topic>
  defaultQuery: string
  from?: Date | string
  language?: string
  page: number
  pageSize: number
  searchInTitle?: boolean
  sortBy: 'relevance' | 'publishedAt'
  to?: Date | string
  type?: Type
  userQuery?: string
}

const queryBuilderNews = ({
  activeTopics,
  defaultQuery,
  from,
  language,
  page,
  pageSize,
  searchInTitle,
  sortBy,
  to,
  type,
  userQuery
}: Props): string => {
  const activeTopicsQuery =
    activeTopics && Array.isArray(activeTopics) && activeTopics?.length
      ? activeTopics
          .map((t: Topic, i: number) =>
            i === activeTopics?.length - 1 ? `${t.query}` : `${t.query} OR `
          )
          .join('')
      : ''

  const dateFilter =
    from && to ? `&from=${from}&to=${to}` : from ? `&from=${from}` : to ? `&to=${to}` : ''

  let query =
    userQuery && activeTopicsQuery
      ? `(${activeTopicsQuery}) AND (${userQuery})`
      : activeTopicsQuery
      ? `${activeTopicsQuery}`
      : userQuery
      ? `${userQuery}`
      : `${defaultQuery}`

  if (type) {
    if (type === 'earnings-calls') query = `(${query}) AND "earnings call transcript"`
    if (type === 'news') query = `(${query}) AND NOT "earnings call transcript"`
  }

  const sort = sortBy && sortBy === 'relevance' ? 'relevancy' : sortBy ? sortBy : 'publishedAt'

  const q = searchInTitle ? `qInTitle=${query}` : `q=${query}`

  return `https://newsapi.org/v2/everything?apiKey=${
    process.env.REACT_APP_NEWS_API
  }&${q}${dateFilter}${
    language ? `&language=${language}` : ''
  }&pageSize=${pageSize}&page=${page}&sortBy=${sort}`
}

export default queryBuilderNews
