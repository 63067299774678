import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Portal } from 'react-portal'
// import { withRouter } from 'react-router-dom'
// import { RouteComponentProps } from 'react-router'
import { useNavigate } from 'react-router-dom'

// API
import { useDocumentLoader } from '../api'

// Components
import Excerpt from '../components/Excerpt'
import Rating from '../components/Rating'

// Store
import { useTopicStore } from '../store'

// Types
import { Criterion, Document, Topic } from '../types'

// Utils
import { data } from '../utils/radar'
import { getExcerpt } from '../utils/string'

// ================================================================================================

interface Props {
  handleNext?: () => void
  handlePrev?: () => void
  onClose: () => void
  open?: boolean
  topic: Topic
}

const Overlay: React.FC<Props> = ({ handleNext, handlePrev, onClose, open, topic }) => {
  const { toggleActiveTopic } = useTopicStore()
  const navigate = useNavigate()
  const { documents, loading: loadingDocuments } = useDocumentLoader({
    topicId: topic && topic.id ? topic.id : ''
  })

  const handleSignalsClick = id => {
    toggleActiveTopic(id)
    navigate('/scout/signals')
  }

  return (
    <AnimatePresence>
      {open && (
        <Portal>
          <Darken
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            onClick={onClose}
            transition={{ ease: 'linear', duration: 0.15 }}
          >
            <Close onClick={onClose}>
              <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M122.485 12.485l-110 110L4 114 114 4l8.485 8.485z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M114 122.485l-110-110L12.485 4l110 110-8.485 8.485z"
                />
              </svg>
            </Close>

            <Card
              animate={{ opacity: 1, x: '-50%', y: 0 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0, x: '-50%', y: 200 }}
              onClick={e => e.stopPropagation()}
              transition={{ type: 'tween', duration: 0.15, delay: 0.15 }}
            >
              {handlePrev && (
                <Navigator onClick={handlePrev}>
                  <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M53.726 63.5L86 7.496 75.603 1.504 39.876 63.5l35.727 61.996L86 119.504 53.726 63.5z"
                    />
                  </svg>
                </Navigator>
              )}

              <Wrapper
                image={
                  topic && topic.image ? topic.image : topic && topic.imageUrl ? topic.imageUrl : ''
                }
              >
                <TitleWrapper>
                  <Title>{topic && topic.title}</Title>
                  <Icon onClick={() => handleSignalsClick(topic.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                      <path d="M49 32a2 2 0 00-2 2v2a2 2 0 002 2h48a2 2 0 002-2v-2a2 2 0 00-2-2H49zM47 58a2 2 0 012-2h48a2 2 0 012 2v2a2 2 0 01-2 2H49a2 2 0 01-2-2v-2zM49 80a2 2 0 00-2 2v2a2 2 0 002 2h48a2 2 0 002-2v-2a2 2 0 00-2-2H49z" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25 8a4 4 0 014-4h85a4 4 0 014 4v112a4 4 0 01-4 4H25v-.166c-.65.109-1.319.166-2 .166-6.627 0-12-5.373-12-12V35a4 4 0 014-4h10V8zm6 110V10h81v108H31zm-6-81v80.659A6 6 0 0117 112V37h8z"
                      />
                    </svg>
                    <span>Show signals</span>
                  </Icon>
                </TitleWrapper>
                <Content>
                  <SubTitle>Description</SubTitle>
                  <Excerpt length={400} showReadMore text={topic && topic.description} />

                  <SubTitle>Assessment</SubTitle>
                  {data &&
                    data.length &&
                    data.map((criterion: Criterion) => {
                      if (criterion && criterion.value && topic[criterion.value]) {
                        return (
                          <Rating
                            options={criterion.data}
                            title={criterion.label}
                            value={criterion && criterion.value ? topic[criterion.value] : ''}
                          />
                        )
                      }
                    })}

                  {!!(documents && documents.length) && (
                    <>
                      <SubTitle>Downloads</SubTitle>
                      <Docs>
                        {documents &&
                          documents.length &&
                          documents.map((d: Document) => (
                            <Doc download href={d.url} rel="noopener noreferrer" target="_blank">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M472 313v139c0 11.028-8.972 20-20 20H60c-11.028 0-20-8.972-20-20V313H0v139c0 33.084 26.916 60 60 60h392c33.084 0 60-26.916 60-60V313h-40z" />
                                <path d="M352 235.716l-76 76V0h-40v311.716l-76-76L131.716 264 256 388.284 380.284 264z" />
                              </svg>
                              <span>{getExcerpt(d.name, 20)}</span>
                            </Doc>
                          ))}
                      </Docs>
                    </>
                  )}
                </Content>
              </Wrapper>

              {handleNext && (
                <Navigator next onClick={handleNext}>
                  <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M72.274 63.5L40 7.496l10.397-5.992L86.124 63.5l-35.727 61.996L40 119.504 72.274 63.5z"
                    />
                  </svg>
                </Navigator>
              )}
            </Card>
          </Darken>
        </Portal>
      )}
    </AnimatePresence>
  )
}

export default Overlay

// ================================================================================================

const Darken = styled(motion.button)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.92);

  &:hover {
    cursor: default;
  }
`

const Card = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  width: calc(100% - 7rem);
  min-width: 280px;
  max-width: 1140px;
  height: 94vh;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
`

const Close = styled.button`
  position: absolute;
  right: 2rem;
  top: 2rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: rgba(255, 255, 255, 0.75);
  }

  &:hover {
    svg {
      fill: #fff;
    }
  }
`

interface NavigatorProps {
  next?: boolean
}
const Navigator = styled.button<NavigatorProps>`
  position: absolute;
  top: 50%;
  right: ${({ next }) => (next ? '-4.5rem' : 'initial')};
  left: ${({ next }) => (next ? 'initial' : '-4.5rem')};
  transform: translateY(-50%);

  svg {
    width: 2.5rem;
    height: 2.5rem;
    fill: rgba(255, 255, 255, 0.75);
  }

  &:hover {
    svg {
      fill: #fff;
    }
  }
`

const Wrapper = styled.div<any>`
  position: relative;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-image: ${({ image }) => `url(${image})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -2;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 100%);
    opacity: 1;
    z-index: -1;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 88%;
  min-width: 260px;
  margin-top: 14vh;
`

const Title = styled.h1`
  color: #fff;
  font-size: 3.25rem;
  text-align: left;
`

const Icon = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 144px;
  margin: 0 0 0.5rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
    fill: rgba(255, 255, 255, 1);
  }

  span {
    color: rgba(255, 255, 255, 1);
    font-size: 0.6875rem;
    margin: 0 0 0 0.5rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

const SubTitle = styled.h2`
  color: ${({ theme }) => theme.text};
  font-size: 1.125rem;
  padding-bottom: 0.875rem;
  border-bottom: 2px solid #eef1f4;
  margin: 3rem 0 1rem;
  text-align: left;
  text-transform: uppercase;
  font-weight: 800;
`

const Content = styled.div`
  position: relative;
  background: ${({ theme }) => theme.surface};
  width: 88%;
  min-width: 260px;
  margin-top: 14vh;
  flex: 1;
  border-radius: 3px 3px 0 0;
  padding: 1.5rem 3rem 3rem;

  p,
  span {
    font-size: 1rem;
    line-height: 1.4;
    text-align: left;
    color: ${({ theme }) => theme.text};
  }
`

const Docs = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 2rem 0 0;
`

const Doc = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0 1.5rem 1rem 0;

  svg {
    width: 2rem;
    height: 2rem;
    fill: #d2d8dc;
    margin: 0 0 0.375rem;
  }

  span {
    color: ${({ theme }) => theme.text};
    font-size: 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    max-width: 5rem;
    text-align: center;
  }

  &:hover {
    svg {
      fill: #243b53;
    }
  }
`
