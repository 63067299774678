import React, { FC } from 'react'
import styled from 'styled-components'

// Types
import { CriterionData } from '../types'

// ================================================================================================

interface Props {
  barHeight?: number
  options: Array<CriterionData>
  title: string
  value: string
}

const Rating: FC<Props> = props => {
  const { barHeight = 16, options, title, value } = props

  return (
    <Wrapper {...props}>
      <Title>{title}</Title>
      <BarWrapper>
        {options.map((option: CriterionData, i) => {
          return (
            <Bar
              active={option.value === value}
              first={i === 0}
              key={`rating-${option}-${i}`}
              last={i === options.length - 1}
            >
              <Label active={option.value === value}>{option.label}</Label>
            </Bar>
          )
        })}
      </BarWrapper>
    </Wrapper>
  )
}

export default Rating

// ================================================================================================

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const BarWrapper = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  margin-bottom: 0.5rem;
`

const Title = styled.h3`
  font-size: 0.75rem;
  margin: 1.25rem 0 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #243b53;
`

const Bar = styled.div<any>`
  flex: 0 1 9rem;
  height: 100%;
  border-right: ${({ last }) => (last ? 'none' : `1px solid #fff`)};
  border-left: ${({ first }) => (first ? 'none' : `1px solid #fff`)};
  background: ${({ active, theme }) => (active ? '#243B53' : theme.base)};
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    border-right: none;
  }
`

interface LabelProps {
  active?: boolean
  size?: number
}
const Label = styled.label<LabelProps|any>`
  color: ${({ active }) => (active ? '#fff' : '#243B53')};
  /* font-size: ${({ baseSize }) => `${baseSize}px`}; */
  font-size: .75rem;
  font-weight: bold;
  line-height: 1;
`
