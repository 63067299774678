import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

// Context
import RadarContext from './RadarContext'

// Partials
import Checkbox from './Checkbox'
import RadarFilterDot from './RadarFilterDot'

// Store
import { useTopicStore } from '../../store'

// Types
import { Dot, DotGroup, Ring, Segment, Option, Color } from './types'

// Utils
import { groupDots } from './utils'

// ================================================================================================

const RadarFilter: React.FC = () => {
  const { toggleActiveTopic } = useTopicStore()
  const navigate = useNavigate()

  const {
    activeDots,
    colorDiff,
    colors,
    colorsSelection,
    dotHighlighting,
    dots,
    groupDotsBySegment,
    handleToggle,
    rings,
    ringsSelection,
    segments,
    segmentsSelection,
    selectOptions,
    setActiveDots,
    setColorDiff,
    setColorsSelection,
    setDotHighlighting,
    setRingsSelection,
    setSegmentsSelection
  } = useContext(RadarContext)
  const groupedDots = groupDotsBySegment
    ? groupDots(dots, segments, segmentsSelection.value ? segmentsSelection.value : '')
    : []

  const [activeTab, setActiveTab] = useState<'filter' | 'topics' | 'settings'>('topics')
  const [openDotIndex, setOpenDotIndex] = useState(0)

  useEffect(() => {
    if (activeDots.length > 0) setActiveTab('topics')
    setOpenDotIndex(activeDots.length - 1)
  }, [activeDots])

  // ============================================

  const handleShowSignals = (topicId: string) => {
    toggleActiveTopic(topicId)
    navigate('/scout/signals')
  }

  // ============================================

  return (
    <>
      <Header>
        <HeaderItem active={activeTab === 'topics'} onClick={() => setActiveTab('topics')}>
          <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M80.88 96.222C73.589 100.53 65.084 103 56 103 28.938 103 7 81.062 7 54S28.938 5 56 5s49 21.938 49 49a48.783 48.783 0 01-9.588 29.12c.197.136.384.291.559.466l25.128 25.129a3.999 3.999 0 010 5.657l-8.485 8.485a4 4 0 01-5.657 0l-25.129-25.13a3.983 3.983 0 01-.947-1.505zM85 54c0 16.016-12.984 29-29 29S27 70.016 27 54s12.984-29 29-29 29 12.984 29 29z"
            />
          </svg>
          <span>Topics</span>
        </HeaderItem>
        <HeaderItem active={activeTab === 'filter'} onClick={() => setActiveTab('filter')}>
          <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <path d="M52.496 73.744L11.11 11.664C9.78 9.67 11.21 7 13.606 7H115.36c2.403 0 3.831 2.684 2.488 4.677L76.013 73.74a3 3 0 00-.513 1.677v40.278a3 3 0 01-2.227 2.899l-16.5 4.4A3 3 0 0153 120.095V75.408a3 3 0 00-.504-1.664z" />
          </svg>
          <span>Settings</span>
        </HeaderItem>
      </Header>

      <FilterContent>
        {activeTab === 'filter' && (
          <>
            {/* <FilterHeadline>Dot Highlighting</FilterHeadline> */}
            <FilterSelect active={true} onClick={() => setDotHighlighting(!dotHighlighting)}>
              <span>Momentum Highlighting</span>
              <Checkbox active={dotHighlighting} />
            </FilterSelect>

            <Separator />

            {segments && <FilterHeadline>Segments</FilterHeadline>}
            {segments && (
              <SelectBox
                defaultValue={segmentsSelection}
                onChange={selection => setSegmentsSelection(selection)}
                options={
                  selectOptions
                    ? selectOptions.filter(
                        (opt: Option) =>
                          opt.value !== ringsSelection.value && opt.value !== colorsSelection.value
                      )
                    : []
                }
                styles={{
                  control: styles => ({
                    ...styles,
                    backgroundColor: 'white',
                    borderRadius: 'none',
                    minHeight: '36px',
                    height: '36px',
                    border: '1px solid #d9e2ec',
                    fontSize: '0.875rem'
                  }),
                  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    return {
                      ...styles,
                      height: '38px',
                      backgroundColor: isDisabled
                        ? null
                        : isSelected
                        ? '#122A43'
                        : isFocused
                        ? '#D9E2EC'
                        : null,

                      ':active': {
                        ...styles[':active']
                      }
                    }
                  },
                  indicatorSeparator: styles => ({ ...styles, backgroundColor: '#d9e2ec' }),
                  input: styles => ({ ...styles }),
                  placeholder: styles => ({ ...styles }),
                  singleValue: (styles, { data }) => ({ ...styles })
                }}
              />
            )}
            {segments && (
              <FilterContainer noShrink>
                {segments.map(({ active, id, label }: Segment) => (
                  <FilterSelect
                    active={active}
                    key={`ring-${id}`}
                    onClick={() => handleToggle('segment', id)}
                  >
                    <span>{label}</span>
                    <Checkbox active={active} />
                  </FilterSelect>
                ))}
              </FilterContainer>
            )}

            {rings && <Separator />}

            {rings && <FilterHeadline>Rings</FilterHeadline>}
            {rings && (
              <SelectBox
                defaultValue={ringsSelection}
                onChange={selection => setRingsSelection(selection)}
                options={
                  selectOptions
                    ? selectOptions.filter(
                        (opt: Option) =>
                          opt.value !== segmentsSelection.value &&
                          opt.value !== colorsSelection.value
                      )
                    : []
                }
                styles={{
                  control: styles => ({
                    ...styles,
                    backgroundColor: 'white',
                    borderRadius: 'none',
                    minHeight: '36px',
                    height: '36px',
                    border: '1px solid #d9e2ec',
                    fontSize: '0.875rem'
                  }),
                  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    return {
                      ...styles,
                      height: '38px',
                      backgroundColor: isDisabled
                        ? null
                        : isSelected
                        ? '#122A43'
                        : isFocused
                        ? '#D9E2EC'
                        : null,

                      ':active': {
                        ...styles[':active']
                      }
                    }
                  },
                  indicatorSeparator: styles => ({ ...styles, backgroundColor: '#d9e2ec' }),
                  input: styles => ({ ...styles }),
                  placeholder: styles => ({ ...styles }),
                  singleValue: (styles, { data }) => ({ ...styles })
                }}
              />
            )}
            {rings && (
              <FilterContainer noShrink>
                {rings.map(({ active, id, label }: Ring) => (
                  <FilterSelect
                    active={active}
                    key={`ring-${id}`}
                    onClick={() => handleToggle('ring', id)}
                  >
                    <span>{label}</span>
                    <Checkbox active={active} />
                  </FilterSelect>
                ))}
              </FilterContainer>
            )}

            {rings && <Separator />}

            {rings && <FilterHeadline>Colors</FilterHeadline>}
            <ColorDiff>
              <FilterSelect active={true} onClick={() => setColorDiff(!colorDiff)}>
                <span>Color Differentiation</span>
                <Checkbox active={colorDiff} />
              </FilterSelect>
            </ColorDiff>

            {colors && colorDiff && (
              <SelectBox
                defaultValue={colorsSelection}
                onChange={selection => setColorsSelection(selection)}
                options={
                  selectOptions
                    ? selectOptions.filter(
                        (opt: Option) =>
                          opt.value !== segmentsSelection.value &&
                          opt.value !== ringsSelection.value
                      )
                    : []
                }
                styles={{
                  control: styles => ({
                    ...styles,
                    backgroundColor: 'white',
                    borderRadius: 'none',
                    minHeight: '36px',
                    height: '36px',
                    border: '1px solid #d9e2ec',
                    fontSize: '0.875rem'
                  }),
                  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    return {
                      ...styles,
                      height: '38px',
                      backgroundColor: isDisabled
                        ? null
                        : isSelected
                        ? '#122A43'
                        : isFocused
                        ? '#D9E2EC'
                        : null,

                      ':active': {
                        ...styles[':active']
                      }
                    }
                  },
                  indicatorSeparator: styles => ({ ...styles, backgroundColor: '#d9e2ec' }),
                  input: styles => ({ ...styles }),
                  placeholder: styles => ({ ...styles }),
                  singleValue: (styles, { data }) => ({ ...styles })
                }}
              />
            )}
            {colors && colorDiff && (
              <FilterContainer noShrink>
                {colors.map(({ code, id, label }: Color) => (
                  <FilterSelect active key={`color-${id}`}>
                    <span>{label}</span>
                    <Circle color={code} />
                  </FilterSelect>
                ))}
                <FilterSelect active>
                  <span>Not set</span>
                  <Circle color="#243B53" />
                </FilterSelect>
              </FilterContainer>
            )}
          </>
        )}

        {/* {activeTab === 'topics' && activeDots.length === 0 && (
          <FilterHeadline>No topics selected</FilterHeadline>
        )} */}

        {activeTab === 'topics' && activeDots.length > 0 ? (
          activeDots.map((dot: Dot, i: number) => {
            return (
              <>
                <Tab>
                  <AccordionHeadline>
                    <button
                      onClick={() => {
                        openDotIndex === i ? setOpenDotIndex(null) : setOpenDotIndex(i)
                      }}
                    >
                      {dot.title}
                    </button>
                    <AccordionClose
                      onClick={() => {
                        const filtered = activeDots.filter((d: Dot) => d.id !== dot.id)
                        setActiveDots(filtered)
                      }}
                    >
                      <svg
                        height="10px"
                        viewBox="0 0 128 128"
                        width="10px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M105.414 14.414a2 2 0 00-2.828 0L64.814 52.186 27.728 15.1a2 2 0 00-2.829 0l-8.485 8.486a2 2 0 000 2.828L53.5 63.5l-38.595 38.595a2 2 0 000 2.829l8.485 8.485a2 2 0 002.828 0l38.596-38.595 39.281 39.281a1.999 1.999 0 002.829 0l8.485-8.485a2 2 0 000-2.828L76.127 63.5 113.9 25.728a2 2 0 000-2.829l-8.486-8.485z" />
                      </svg>
                    </AccordionClose>
                  </AccordionHeadline>

                  <AnimatePresence initial={false}>
                    {openDotIndex === i && (
                      <AccordionContent
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                          open: { opacity: 1, height: 'auto' },
                          collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
                      >
                        <Topic>
                          <p>{dot.description}</p>

                          <TopicButton onClick={() => handleShowSignals(dot.id)}>
                            Show Signals
                          </TopicButton>
                        </Topic>
                      </AccordionContent>
                    )}
                  </AnimatePresence>
                </Tab>

                {i !== activeDots.length - 1 && <Separator />}
              </>
            )
          })
        ) : activeTab === 'topics' && dots && dots.length ? (
          <>
            {/* <FilterHeadline>Trend Topics</FilterHeadline> */}
            <FilterHeadline>{segmentsSelection.label}</FilterHeadline>
            <FilterContainer>
              {groupDotsBySegment
                ? groupedDots.map(({ dots, segmentLabel }: DotGroup) => (
                    <>
                      <FilterSelectHeading>{segmentLabel}</FilterSelectHeading>
                      {dots.map((dot: Dot) => (
                        <RadarFilterDot dot={dot} key={`dot-${dot.id}`}>
                          <span>{dot.title}</span>
                        </RadarFilterDot>
                      ))}
                    </>
                  ))
                : dots.map((dot: Dot) => (
                    <RadarFilterDot dot={dot} key={`dot-${dot.id}`}>
                      <span>{dot.title}</span>
                    </RadarFilterDot>
                  ))}
            </FilterContainer>
          </>
        ) : (
          ''
        )}
      </FilterContent>
    </>
  )
}

export default RadarFilter

// ================================================================================================

const Header = styled.header`
  height: 3.25rem;
  width: 100%;
  display: flex;
`

const HeaderItem = styled.button<any>`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ active, theme }) => (active ? '#f8f9fc' : 'transparent')};
  border-radius: 4px 4px 0 0;

  svg {
    width: 0.75rem;
    height: 0.75rem;
    fill: ${({ theme }) => theme.text};
    margin-right: 0.375rem;
  }

  span {
    font-weight: bold;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.text};
  }
`

const FilterContent = styled.div`
  width: 100%;
  /* max-height: 90vh;
  max-height: calc(100vh - 3rem - 3rem - 6vh); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f8f9fc;
  padding: 1.5rem 2rem 2rem;
  border-radius: 0 0 4px 4px;
  overflow-y: auto;
`

const FilterHeadline = styled.button`
  font-weight: bold;
  font-size: 0.875rem;
  margin: 0.25rem 0 0.75rem;
`

interface FilterContainerProps {
  noShrink?: boolean
}
const FilterContainer = styled.div<FilterContainerProps>`
  width: 100%;
  max-height: 45vh;
  flex-shrink: ${({ noShrink }) => (noShrink ? 0 : 1)};
  overflow-y: auto;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const SelectBox = styled(Select)<any>`
  width: 100%;
  margin: 0 0 1rem;

  svg {
    fill: #d9e2ec;
  }
`

const FilterSelect = styled.button<any>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.375rem;
  flex-shrink: 0;

  span {
    font-weight: 400;
    font-size: 0.8125rem;
    color: ${({ active, theme }) => (active ? theme.text : '#f2f2f2')};
    line-height: 1.2;
  }

  &:hover {
    span {
      color: ${({ active, theme }) => (active ? theme.text : '#f2f2f2')};
    }
  }
`

const FilterSelectHeading = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 0.8125rem;
  margin: 0.5rem 0 0.375rem;
  color: rgba(34, 58, 82, 0.4);
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #d9e2ec;
  margin: 1rem 0;
  flex-shrink: 0;
`

const Tab = styled.div`
  width: 100%;
`

const Topic = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 0.8125rem;
    color: ${({ theme }) => theme.text};
    margin: 0.375rem 0 0.8125rem;
  }
`

const TopicButton = styled.button`
  width: 110px;
  height: 32px;
  background: #122a43;
  color: #fff;
  font-size: 0.6875rem;
  font-weight: 600;
  margin-top: 0.5rem;
  border-radius: 2px;
`

const AccordionHeadline = styled.div`
  height: 2rem;
  margin-top: 0;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    font-size: 0.875rem;
    font-weight: bold;
    color: ${({ theme }) => theme.text};
  }
`

const AccordionClose = styled.button`
  svg {
    color: ${({ theme }) => theme.text};
    transform-origin: center !important;
  }
`

const AccordionContent = styled(motion.div)``

interface CircleProps {
  color: string
}
const Circle = styled.div<CircleProps>`
  background: ${({ color }) => color};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`

const ColorDiff = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
`
