import React, { useState, SyntheticEvent } from 'react'
import styled from 'styled-components'
import * as Parse from 'parse'

// Assets
import backgroundVideoMp4 from '../assets/video/bg.mp4'
import backgroundVideoWebm from '../assets/video/bg.webm'
import backgroundImage from '../assets/img/bg.png'

// Components
import Checkbox from '../components/Checkbox'
import Loader from '../components/Loader'
import { useNavigate } from 'react-router-dom'

// Store
import { useDialogStore } from '../store'

// ================================================================================================

export interface HandleSignUpProps {
  email: string
  name?: string
  password: string
}

const SignUpPage: React.FC = () => {
  // Store (global)
  const { showErrorDialog, showSuccessDialog } = useDialogStore()
  const navigate = useNavigate()
  // State (local)
  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [termsAccepted, setTermsAccepted] = useState(false)

  Parse.initialize(
    process.env.REACT_APP_PARSE_APP_ID,
    process.env.REACT_APP_PARSE_JS_ID,
    process.env.REACT_APP_MASTER_KEY
  )
  // @ts-ignore
  Parse.serverURL = 'https://fiso-parse-server.ovhkube-prod.intuitive-ai.com/parse'

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!loading) {
      setLoading(true)

      if (email && password && passwordConfirm && termsAccepted) {
        if (password === passwordConfirm) {
          const user = new Parse.User()

          user.set('email', email)
          user.set('password', password)
          user.set('username', email)

          try {
            await user.signUp() // unsuccessful login throws error

            await Parse.User.logOut() // logout user since signUp logs user in

            showSuccessDialog(`Please check your emails and confirm your email address ${email}.`)
            navigate('/')
          } catch (error) {
            showErrorDialog(`Error: ${error.code} ${error.message}`)
          }
        } else {
          showErrorDialog(`Please make sure the passwords match.`)
        }
      }

      setLoading(false)
    }
  }

  // ============================================

  return (
    <Wrapper>
      <Video autoPlay loop muted>
        <source src={backgroundVideoWebm} type="video/webm" />
        <source src={backgroundVideoMp4} type="video/mp4" />
      </Video>

      <Content>
        <Title>
          <b>Foresight Intelligence</b> System
        </Title>

        <Form>
          <Textfields>
            <Textfield
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
              placeholder="Email"
              type="email"
              value={email}
            />
            <Textfield
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.currentTarget.value)
              }
              placeholder="Password"
              type="password"
              value={password}
            />
            <Textfield
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPasswordConfirm(e.currentTarget.value)
              }
              placeholder="Confirm password"
              type="password"
              value={passwordConfirm}
            />
          </Textfields>

          <Terms>
            <Checkbox
              activeColor="#2498a2"
              checked={termsAccepted}
              color="rgba(255, 255, 255, 0.25)"
              onChange={() => setTermsAccepted(prev => !prev)}
            />
            <Label>
              I agree to the intuitive.AI{' '}
              <a href="https://www.intuitive-ai.de/terms" rel="noopener noreferrer" target="_blank">
                terms and conditions
              </a>{' '}
              of us and{' '}
              <a href="terms-of-use.html" rel="noopener noreferrer" target="_blank">
                terms of use for intuitive.ai GmbH services
              </a>{' '}
              as well as the{' '}
              <a href="privacy.html" rel="noopener noreferrer" target="_blank">
                privacy statement
              </a>
            </Label>
          </Terms>

          <Submit onClick={handleSubmit}>
            {loading ? <Loader color="rgba(255, 255, 255, 0.9)" size={26} stroke={4} /> : 'Sign Up'}
          </Submit>
        </Form>
      </Content>
    </Wrapper>
  )
}

export default SignUpPage

// ================================================================================================

const H = '46px'

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-image: ${`url(${backgroundImage})`};
  background-size: cover;
  background-position: center;

  @media (min-width: 1024px) and (orientation: landscape) {
    background-position: right bottom;
  }
`

const Video = styled.video`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: 1;
  transition: opacity 5s;
  pointer-events: none;
`

const Content = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 960px;
  padding: 0 6vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const Title = styled.h1`
  font-size: 2rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.99);
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 300;
  letter-spacing: 0.5px;

  b {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 800;
    color: rgba(255, 255, 255, 0.99);
  }
`

const Form = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 2.25rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const Textfields = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const Textfield = styled.input`
  background-color: rgba(255, 255, 255, 0.25);
  border: none;
  color: #fff;
  font-size: 0.875rem;
  height: ${H};
  padding: 0 1.25rem;
  width: 100%;
  max-width: 20rem;
  margin: 0 0 1rem 0;
  border-radius: 1px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;

  &:focus {
    outline: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.4);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.4);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.4);
  }

  @media (min-width: 1024px) {
    width: 32%;
    max-width: none;
  }
`

export const Submit = styled.button`
  background-color: #2498a2;
  border-radius: 1px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  height: ${H};
  width: 100%;
  max-width: 20rem;
  text-transform: uppercase;
  flex-shrink: 0;

  &:hover {
    background-color: #28a4af;
  }

  @media (min-width: 1024px) {
    max-width: none;
    width: 40%;
  }
`

const Terms = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0 1.5rem;
  width: 100%;
  max-width: 20rem;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    max-width: none;
  }
`

const Label = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.15;
  color: rgba(255, 255, 255, 0.67);
  margin: -2px 0 0 0.75rem;

  a {
    font-size: 0.875rem;
    line-height: 1.15;
    color: rgba(255, 255, 255, 0.67);
    text-decoration: underline;
    font-weight: 400;
  }
`
