import React, { createContext, FC, useEffect, useState } from 'react'
// import { RouteComponentProps } from 'react-router'
// import { withRouter } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import * as Parse from 'parse'

// ================================================================================================
interface UserStoreProps {
  children: React.ReactNode;
}

export const UserContext = createContext({
  email: '',
  defaultQuery: '',
  fetchUserRating: () => {},
  setDefaultQuery: (query: string) => {},
  setEmail: (email: string) => {},
  setUserIsAdmin: (isAdmin: boolean) => {},
  setUserIsExternal: (isExternal: boolean) => {},
  setUserSignedIn: (signedIn: boolean) => {},
  userIsAdmin: false,
  userIsExternal: false,
  userSignedIn: false
})

// ================================================================================================

const UserStore: FC<UserStoreProps> = ({ children }: UserStoreProps) => {
  const [defaultQuery, setDefaultQuery] = useState('')
  const [email, setEmail] = useState('')
  const [userIsAdmin, setUserIsAdmin] = useState(false)
  const [userIsExternal, setUserIsExternal] = useState(false)
  const [userSignedIn, setUserSignedIn] = useState(false)

  Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_ID)
  // @ts-ignore
  Parse.serverURL = 'https://fiso-parse-server.ovhkube-prod.intuitive-ai.com/parse'

  const navigate = useNavigate()

  useEffect(() => {
    const signedInUser = Parse.User.current()

    if (signedInUser) {
      // Update context
      setUserSignedIn(true)
      const defaultQuery = signedInUser.get('defaultQuery')
      if (defaultQuery) {
        setDefaultQuery(defaultQuery)
      }

      const email = signedInUser.get('email')
      if (email) setEmail(email)

      const isAdmin = signedInUser.get('isAdmin')
      if (isAdmin) setUserIsAdmin(isAdmin)

      const isExternal = signedInUser.get('isExternal')
      if (isExternal) setUserIsExternal(isExternal)

      if (
        userIsExternal &&
        navigate &&
        window.location &&
        window.location.pathname &&
        window.location.pathname !== '/evaluate/rating'
      ) {
        navigate('/evaluate/rating')
      }
    } else {
      navigate('/')
    }
  }, [navigate, userIsExternal])

  const fetchUserRating = async () => {}

  return (
    <UserContext.Provider
      value={{
        defaultQuery,
        email,
        fetchUserRating,
        setDefaultQuery,
        setEmail,
        setUserIsAdmin,
        setUserIsExternal,
        setUserSignedIn,
        userIsAdmin,
        userIsExternal,
        userSignedIn
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserStore
