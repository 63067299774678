import { format, formatDistanceToNow, parseISO } from "date-fns";

const formatDate = (date: any, formatting?: string) => {
  try {
    let parsedDate: Date | number;

    // Parse date if necessary
    if (typeof date === "string") {
      parsedDate = parseISO(date);
    } else {
      parsedDate = date;
    }

    // const formattedDate = format(parsedDate, formatting ? formatting : 'yyyy-MM-dd')
    // return formattedDate.length === 10 ? formattedDate : date

    const formattedDate = formatDistanceToNow(parsedDate, { addSuffix: true });
    return formattedDate
      ? formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
      : date;
  } catch (error) {
    return date;
  }
};

export default formatDate;
