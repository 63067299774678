import * as React from 'react'
import styled from 'styled-components'

// ================================================================================================

export interface TextInputProps {
  bold?: boolean
  icon?: 'location' | 'search'
  label?: string
  onClear: () => void
  onSubmit?: () => void
  onTextChange: (input: string) => void
  placeholder?: string
  value: string
  width?: string
}

// ================================================================================================

const TextInput: React.FC<TextInputProps> = ({
  bold,
  icon,
  label,
  onClear,
  onSubmit,
  onTextChange,
  placeholder,
  value,
  width
}) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onTextChange(e.currentTarget.value)
  }

  return (
    <Wrapper width={width}>
      {!!label && <Label>{label}</Label>}

      <InputWrapper label={!!label}>
        <Icon>
          {icon === 'search' ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M86.521 92.664a47.45 47.45 0 01-29.89 10.537C30.325 103.201 9 81.89 9 55.601 9 29.31 30.325 8 56.63 8c26.306 0 47.631 21.311 47.631 47.6 0 12.369-4.72 23.635-12.458 32.099l26.59 26.573a2.068 2.068 0 010 2.927l-2.196 2.195a2.073 2.073 0 01-2.929 0l-26.747-26.73zM97.013 55.6c0 22.288-18.08 40.357-40.382 40.357-22.303 0-40.383-18.069-40.383-40.357 0-22.289 18.08-40.357 40.383-40.357 22.302 0 40.382 18.068 40.382 40.357z"
              />
            </svg>
          ) : icon === 'location' ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M87.295 47.213c0 12.748-10.334 23.082-23.082 23.082-12.748 0-23.082-10.334-23.082-23.082 0-12.748 10.334-23.082 23.082-23.082 12.748 0 23.082 10.334 23.082 23.082zm-5.77 0c0 9.561-7.751 17.312-17.312 17.312-9.56 0-17.311-7.751-17.311-17.312 0-9.56 7.75-17.311 17.311-17.311s17.312 7.75 17.312 17.311z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M111.426 46.357c0 31.722-42.25 78.291-44.05 80.258-1.686 1.845-4.636 1.848-6.326 0C59.251 124.648 17 78.079 17 46.357 17 20.796 38.18 0 64.213 0s47.213 20.796 47.213 46.357zm-88.13 0c0-21.977 18.251-40.062 40.917-40.062s40.918 18.085 40.918 40.062c0 6.654-2.251 14.57-6.074 23.061-3.788 8.41-8.913 16.936-14.186 24.685-8.532 12.539-17.223 22.712-20.658 26.607-3.435-3.895-12.125-14.068-20.658-26.607-5.273-7.749-10.398-16.275-14.185-24.685-3.824-8.49-6.075-16.407-6.075-23.061z"
              />
            </svg>
          ) : (
            ''
          )}
        </Icon>

        <Input
          bold={bold}
          onChange={handleChange}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 || e.keyCode === '13') {
              onSubmit()
            } else {
              console.log('asdasdasdads')
            }
          }}
          placeholder={placeholder}
          type="text"
          value={value}
        />

        <Close onClick={onClear}>
          <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <path d="M105.414 14.414a2 2 0 00-2.828 0L64.814 52.186 27.728 15.1a2 2 0 00-2.829 0l-8.485 8.486a2 2 0 000 2.828L53.5 63.5l-38.595 38.595a2 2 0 000 2.829l8.485 8.485a2 2 0 002.828 0l38.596-38.595 39.281 39.281a1.999 1.999 0 002.829 0l8.485-8.485a2 2 0 000-2.828L76.127 63.5 113.9 25.728a2 2 0 000-2.829l-8.486-8.485z" />
          </svg>
        </Close>
      </InputWrapper>
    </Wrapper>
  )
}

// ================================================================================================

interface WrapperProps {
  width?: string
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-child {
    margin-right: 1rem;
  }

  @media (min-width: 1024px) {
    width: ${({ width }) => (width ? width : '100%')};
  }
`

const Label = styled.label`
  font-size: 0.75rem;
  margin: 0 0 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
`

interface InputWrapperProps {
  label?: boolean
}
const InputWrapper = styled.div<InputWrapperProps>`
  width: 100%;
  background: ${({ theme }) => theme.base};
  height: 3.125rem;
  display: flex;
  align-items: center;
  padding: ${({ label }) => (label ? '0 0.75rem 0 0' : '0 1.5rem')};
  border-radius: 4px;
`

const Icon = styled.div`
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: ${({ theme }) => theme.text};
  }
`

interface InputProps {
  bold?: boolean
}
const Input = styled.input<InputProps>`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.text};
  font-size: 0.9375rem;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  &:focus {
    outline: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ced2d7;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ced2d7;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ced2d7;
  }
`

const Close = styled.button`
  background: #e2e6ea;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;

  svg {
    width: 0.75em;
    height: 0.75em;
    fill: #a5adb2;
  }
`

// ================================================================================================

export default TextInput
